<!-- 退票 -->
<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>退票管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <!-- 搜索框 -->
      <el-row :gutter="24">
        <el-col :span="8">
            <el-input placeholder="请输入订单号" clearable v-model="queryInfo.orderNo" class="input-with-select">
                <el-button slot="append" icon="el-icon-search" @click="getOrderList()"></el-button>
            </el-input>
        </el-col>
        <el-col :span="8">
            <el-select v-model="queryInfo.status" clearable placeholder="订单状态" @change="getOrderList()">
                <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
        </el-col>
        <el-col :span="8">
          <el-button type="warning" @click="refundDialogVisible=true">一键退票</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="4">
          <el-input
            placeholder="请输入区域"
            clearable
            v-model="queryInfo.area_info.area_name"
            class="input-with-select"
          >
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input
            placeholder="请输入行号"
            clearable
            v-model="queryInfo.area_info.row"
            class="input-with-select"
          >
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input
            placeholder="请输入列号"
            clearable
            v-model="queryInfo.area_info.col"
            class="input-with-select"
          >
          </el-input>

        </el-col>
        <el-col :span="4">
          <el-date-picker
            type="date"
            placeholder="选择起始日期"
            v-model="queryInfo.startTime"
            @change="getOrderList"
          ></el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-date-picker
            type="date"
            placeholder="选择截止日期"
            v-model="queryInfo.endTime"
            @change="getOrderList"
          ></el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" icon="el-icon-search" @click="getOrderList"></el-button>
          <el-button type="primary" icon="el-icon-delete"  @click="cleanArea()"></el-button>
        </el-col>
      </el-row>
      <!-- 订单列表 -->
      <el-table :data="orderList" border stripe>
         <el-table-column type="expand">
           <template slot-scope="props">
             <el-form label-position="left" inline class="demo-table-expand">
               <el-form-item label="订单号: ">
                 <span>{{ props.row.订单号 || "无" }}</span>
               </el-form-item>
               <el-form-item label="票种名称: ">
                 <span>{{ props.row.区 || "无" }}</span>
               </el-form-item>
               <el-form-item label="购票时间: ">
                 <span>{{ props.row.购买时间 || "无" }}</span>
               </el-form-item>
               <el-form-item label="场次: ">
                 <span>{{ props.row.省 || "" }}</span>
               </el-form-item>
               <el-form-item label="价格: ">
                 <span>{{ props.row.价格 || "0" }}</span>
               </el-form-item>
               <el-form-item label="预计使用时间: ">
                 <span>{{ props.row.预计使用时间 || "无" }}</span>
               </el-form-item>
               <el-form-item label="验证时间: ">
                 <span>{{ props.row.验证时间 || "无" }}</span>
               </el-form-item>
               <el-form-item label="验证人: ">
                 <span>{{ props.row.验票员?.管理员姓名 || "无" }}</span>
               </el-form-item>
               <el-form-item label="姓名: ">
                 <span>{{ props.row.收货人 || "匿名" }}</span>
               </el-form-item>
               <el-form-item label="手机号: ">
                 <span>{{ props.row.手机号 || "无" }}</span>
               </el-form-item>
               <el-form-item label="使用状态: ">
                 <span>{{ props.row.使用状态 || "无" }}</span>
               </el-form-item>
               <el-form-item
                   label="关联旅社: "
                   v-if="props.row.是否团队票 == '1'"
               >
                <span>{{
                    props.row.团队?.机构名称 || props.row.订单来源
                  }}</span>
               </el-form-item>
               <el-form-item
                   label="订单来源: "
                   v-if="props.row.是否团队票 != '1'"
               >
                 <span>{{ props.row.订单来源 || "无" }}</span>
               </el-form-item>
               <el-form-item
                   label="团队名称: "
                   v-if="props.row.是否团队票 == '1'"
               >
                 <span>{{ props.row.来源订单号 || "无" }}</span>
               </el-form-item>
               <el-form-item
                   label="订单凭证: "
                   v-if="props.row.是否团队票 != '1'"
               >
                 <span>{{ props.row.来源订单号 || "无" }}</span>
               </el-form-item>
               <el-form-item label="售票员: ">
                 <span>{{ props.row.售票员?.管理员姓名 || "无" }}</span>
               </el-form-item>
               <el-form-item label="核销渠道: ">
                 <span>{{ props.row.核销渠道 || "无" }}</span>
               </el-form-item>
               <el-form-item label="商家退款员: ">
                 <span>{{ props.row.退款员?.管理员姓名 || "无" }}</span>
               </el-form-item>
               <el-form-item label="出票时间: ">
                 <span>{{ props.row.取票时间|| "未出票" }}</span>
               </el-form-item>
               <el-form-item label="商家出票员: ">
                 <span>{{ props.row.AuditName || "无" }}</span>
               </el-form-item>
               <el-form-item label="备注: ">
                 <span>{{ props.row.FailReason || "无" }}</span>
               </el-form-item>
               <el-form-item label="订单明细: ">
                 <el-link
                     type="primary"
                     @click="orderDetail(props.row.ID)"
                     underline
                 >查看<i class="el-icon-view el-icon--right"></i
                 ></el-link>
               </el-form-item>
             </el-form>
           </template>
        </el-table-column>
        <el-table-column label="订单号" prop="订单号" width="120"></el-table-column>
        <el-table-column label="票种名称" prop="区"></el-table-column>
        <el-table-column label="区域" prop="座位区域" width="50"></el-table-column>
        <el-table-column
            label="购买时间"
            width="130"
            prop="购买时间"
        ></el-table-column>
        <el-table-column
            label="场次"
            width="150"
            prop="省"
        >
          <template slot-scope="scope">
            {{ (scope.row["省"]||'').replace(/-/g,'/').slice(0, 16) }}
          </template>
        </el-table-column>
        <el-table-column label="用户信息" width="120">
          <template slot-scope="scope">
            <div>{{ scope.row["手机号"]||'' }}</div>
            {{ scope.row["收货人"]||'匿名' }}
          </template>
        </el-table-column>
        <el-table-column label="数量" prop="数量" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row['使用状态']=='已退款'">0</span><span v-else>{{ scope.row["数量"]-scope.row['cancel_num']||0}}单|{{scope.row['座位数']}}张</span>
          </template>
        </el-table-column>
        <el-table-column label="使用状态" prop="使用状态"></el-table-column>
        <el-table-column label="价格" prop="价格">
          <template slot-scope="scope">
            <span v-if="scope.row['使用状态']=='已退款'">0</span><span v-else>{{ scope.row["价格"] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="渠道" prop="位置">
          <template slot-scope="scope">
            <span v-if="scope.row['订单来源']=='小程序'">微信小程序</span><span v-if="scope.row['订单来源']=='票付通'">九江万达</span><span v-else>{{ scope.row["位置"] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="支付方式" prop="订单来源">
          <template slot-scope="scope">
            {{ scope.row["订单来源"] | getPayType(scope.row["支付方式"]) }}
          </template>
        </el-table-column>
        <el-table-column label="出票信息" width="200">
          <template slot-scope="scope">
            <span v-if="scope.row['是否取票']==0">未出票</span>
            <span v-else>{{scope.row['取票时间']}},出票人:{{scope.row['AuditName']}}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="售票员"
            prop="售票员.管理员姓名"
        ></el-table-column>
        <el-table-column
            label="备注"
            prop="FailReason"
        ></el-table-column>
        <el-table-column
        fixed="right"
        label="操作"
        width="100">
        <template slot-scope="scope">
            <el-button @click="refundHandleClick(scope.row.ID)" type="text" size="small">退款</el-button>
        </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-size="queryInfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 一键退票 -->
    <el-dialog title="一键退票" :visible.sync="refundDialogVisible" width="50%">
     <template>
       <div style="width: 60%">
         <div>
           <p>选择要退票的演出日期：</p>
           <el-date-picker
             type="date"
             :clearable="false"
             placeholder="请选择日期"
             v-model="selectedDate"
             @change="getSessionList()"
             style="width:100%"
           ></el-date-picker>
         </div>
         <div>
           <el-select
             v-model="selectedValue"
             placeholder="请选择场次"
             @change="handleClick"
             style="width:100%"
           >
             <el-option
               v-for="(item, index) in sessionList"
               :key="index"
               :label="item.放映影片.名称 + '：' + item.开始时间"
               :value="item.ID"
             >
             </el-option>
           </el-select>
         </div>
       </div>

     </template>
      <div slot="footer" class="dialog-footer">
        <el-button @click="refundDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureRefundQuiteClick()">确认退款</el-button>
      </div>
    </el-dialog>
    <!-- 订单明细 -->
    <el-dialog title="订单明细页" :visible.sync="showInfoDialogVisible" width="50%">
      <template>
        <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="订单号: ">
                <span>{{ orderDetailInfo.订单号 || '无' }}</span>
            </el-form-item>
            <el-form-item label="总金额: ">
                <span>{{ orderDetailInfo.价格 || '无' }}</span>
            </el-form-item>
            <el-form-item label="出票时间: ">
                <span>{{ orderDetailInfo.购买时间 || '无' }}</span>
            </el-form-item>
            <el-form-item label="预定日期: ">
                <span>{{ orderDetailInfo.预计使用时间 || '无' }}</span>
            </el-form-item>
        </el-form>

        <!-- 订单明细 -->
        <el-table :data="orderDetailInfo.订单明细" border stripe>
            <el-table-column type="index"></el-table-column>
            <el-table-column label="名称" prop="名称"></el-table-column>
            <el-table-column label="总价" prop="价格"></el-table-column>
            <el-table-column label="数量" prop="数量"></el-table-column>
            <el-table-column label="剩余数量" prop="剩余数量"></el-table-column>
        </el-table>

        <!-- 订单人员 -->
        <el-table :data="orderDetailInfo.订单人员" v-if="orderDetailInfo.订单人员 && orderDetailInfo.订单人员.length > 0" border stripe>
            <el-table-column type="index"></el-table-column>
            <el-table-column label="客户姓名" prop="姓名"></el-table-column>
            <el-table-column label="手机号" prop="手机号码"></el-table-column>
            <el-table-column label="身份证号" prop="身份证号码"></el-table-column>
        </el-table>

        <!-- 订单座位 -->
        <el-table :data="seatList" v-if="orderDetailInfo.订单座位 && orderDetailInfo.订单座位.length > 0" border stripe  ref="multipleTable" @selection-change="seatChange">
            <el-table-column
                v-if="orderDetailInfo.使用状态!=='已退款'"
                :selectable="selectable"
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column v-else label="状态">
              <template  slot-scope="scope">
                {{scope.row.EntityStatus===1?'已退款':'单独退款'}}
              </template>
            </el-table-column>
            <el-table-column type="index"></el-table-column>
            <el-table-column label="座位名称" prop="名称"></el-table-column>
            <el-table-column label="区域" prop="区域名"></el-table-column>
            <el-table-column label="演出厅" prop="影厅名称"></el-table-column>
            <el-table-column label="是否检票">
              <template slot-scope="scope">
                <span v-if="scope.row['EntityStatus']==4">已退票</span>
                <span v-else-if="scope.row['isChecked']==1">未检票</span>
                <span v-else>已检票<br>{{scope.row['ChangedTime']}}</span>
              </template>
            </el-table-column>
        </el-table>

        <div class="sub-title" v-if="orderDetailInfo.isRefund">提示：请确认无误后再进行下一步操作</div>

        <div slot="footer" class="dialog-footer" v-if="orderDetailInfo.isRefund">
            <el-button @click="showInfoDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="sureRefundHandleClick(orderDetailInfo.ID)">确认退款</el-button>
        </div>
    </template>

    </el-dialog>
  </div>
</template>

<script>

import DateFormat from "@/utils/DateFormat";

export default {
  name: "order",
  data() {
    return {
      selectedDate: new Date(),
      selectedValue: "",
      sessionList: [],

      queryInfo: {
        searchStr: "",
        // 当前的页数
        currentPage: 1,
        pageSize: 10,
        status: '',
        startTime: '',
        endTime: '',
        userId: '',
        orderNo: '',
        area_info:{area_name:'',row:'',col:''}
      },
      total: 0,
      showInfoDialogVisible: false,
      refundDialogVisible: false,
      orderList: [],
      seatList:[],
      statusOptions: [{
        //   value: '0',
        //   label: '未支付'
        // }, {
          value: '1',
          label: '已支付'
        }, {
          value: '2',
          label: '已完成'
        }, {
          value: '3',
          label: '已退款'
        // }, {
        //   value: '4',
        //   label: '已关闭'
        }],
      orderDetailInfo: {},
      orderDetailPage: 1,
      orderTypeOptions: [],
      goodsTotal: 0,
    };
  },
  created() {
    this.getOrderList();
  },
  computed: {},
  methods: {
    cleanArea(){
      this.queryInfo.area_info={area_name: '',row:'',col:''}
      this.getOrderList()
    },
    getSessionList() {
      let date = new DateFormat(this.selectedDate).toString(
        "yyyy-0m-0dT08:00:00"
      );
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.$axios({
        url: `/manager/sessionList?currentPage=1&pageSize=10&status=4&selectedDate=${date}`,
      })
        .then((res) => {
          this.sessionList = res.data;
          //座位列表
          if (this.sessionList && this.sessionList.length > 0) {
            console.log("this.sessionList: ", this.sessionList);
            this.selectedValue = this.sessionList[0].ID;
            loading.close();
          } else {
            loading.close();
            this.$message({
              type: "error",
              message: "暂无票务信息!",
            });
          }
        })
        .catch((err) => {
          loading.close();
          console.log(err);
        });
    },
    handleClick(e){
      console.log(e)
    },
    sureRefundQuiteClick(){
      this.$axios({
        method: 'get',
        url: "/manager/orderList",
        params: {id:this.selectedValue},
      })
        .then((res) => {
          // console.log(res.data);
          this.orderList = res.data;
          this.total = res.total;
        })
        .catch((err) => err);

    },
    selectable(row){
      return row.EntityStatus===1
    },
    getOrderList() {
      function checkObjectValues(obj) {
        const values = Object.values(obj);
        if (values.every(value => value === "")) {
          return true; // 如果所有值都是空，则返回true
        } else if (values.every(value => value !== "")) {
          return true; // 如果所有值都不是空，则返回true
        } else {
          return false; // 否则返回false
        }
      }
      if (!checkObjectValues(this.queryInfo.area_info)) {
        this.$message.warning('座位查询时请将区域、行号、列号全部填写');
        return
      }
        let isAdmin = window.sessionStorage.getItem("isAdmin");
        if (isAdmin === "0") {
            this.$message.error('无操作权限');
            return
        }

        this.$axios({
          method: 'get',
          url: "/manager/orderList",
          params: this.queryInfo,
        })
        .then((res) => {
          // console.log(res.data);
          this.orderList = res.data;
          this.total = res.total;
        })
        .catch((err) => err);
    },
    //页码改变函数
    handleCurrentChange(newPage) {
      this.queryInfo.currentPage = newPage;
      this.getOrderList();
    },
    orderDetail(id, isRefund) {
        this.orderDetailInfo = {}
        console.log(id)
        this.$axios({
            method: 'get',
            url: "/manager/orderDetail",
            params: {
                orderId: id,
                pageNum: this.orderDetailPage
            },
        })
        .then((res) => {
          // console.log(res.data);
          if (res.code == 200) {
            this.orderDetailInfo = res.data;
            this.orderDetailInfo.isRefund = isRefund;
            this.showInfoDialogVisible = true
            this.seatList=this.orderDetailInfo.订单座位.map(v=>{
              v.selected=true
              return v
            })
            this.seatChange(this.seatList)

            // console.log(this.$refs('seat_table')[0])
            // this.$refs('seat_table')[0].toggleAllSelection()
          } else {
              this.$message.warning(res.msg)
          }
        })
        .catch((err) => err);
    },
    seatChange(e){
      let _seatList=JSON.parse(JSON.stringify(this.seatList))
      for (let item of _seatList){
        item.selected=false
        for (let itemB of e){
          if (item.ID===itemB.ID){
            item.selected=true
          }
        }
      }
      this.orderDetailInfo.订单座位=_seatList
      console.log(e)
    },
    refundHandleClick(id) {
        console.log("退款 ========>> ", id)
        this.orderDetail(id, 1)
    },
    sureRefundHandleClick(id) {
      let that = this
      let refund_seatList=this.orderDetailInfo.订单座位.filter(v=> v.selected&&v.EntityStatus===1)

      console.log("确认退款 ========>> ", id)
      console.log("seat========>> ",refund_seatList )
      if(refund_seatList.length===0){
        that.$message.warning("退款坐席为空，请至少选中一个")
        return;
      }
      const loading = that.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      let data = {
        订单ID: id
      };
      if (refund_seatList.length!==this.orderDetailInfo.订单座位.filter(v=> v.EntityStatus===1).length){
        data.seat=refund_seatList
      }
      that.$axios({
          url: `/order/refund`,
          method: "POST",
          data,
      })
        .then((res) => {
          console.log(res);
          loading.close();
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: "退票成功!",
            });

            this.showInfoDialogVisible = false
            this.getOrderList()
          } else {
            that.$message.warning(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)

          that.$message.warning("网络异常，请稍后再试")
          loading.close();
        });
    },
  },
  watch:{
    'seatList': function () {
      this.$nextTick(() => {
        this.seatList.forEach((item) => {
          if (item.selected) {
            this.$refs.multipleTable.toggleRowSelection(item, true);
          }
        })
      })
  }
  }
}
</script>

<style scoped>

  .sub-title{
    font-size: 12px;
    color: red;
  }
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }

</style>
